import Script from 'next/script'
import { WUNDERKIND_ID } from '@/services/Configuration'
import { useContentLoaded } from '@/hooks/useContentLoaded'
import { useEffect } from 'react'

const CONTAINER_ID = '[id^=bx-campaign-]'
const CLOSE_BUTTON_ID = '[id^=bx-close-inside]'

const WunderKind: React.FC = () => {
  const isLoaded = useContentLoaded()

  useEffect(() => {
    const clickOnGreyBackground = (event : MouseEvent) => {
      const isBackgroundElement = ({ className } : Element) => className === 'bx-slab'
      const getBackgroundElement = ({ children } : Element) => (
        [...children].find(isBackgroundElement)
      )
      const wunderkindRoot = [...document.querySelectorAll(CONTAINER_ID)].find(getBackgroundElement)
      if (wunderkindRoot) {
        const backgroundElement = getBackgroundElement(wunderkindRoot)
        const innerElement = backgroundElement?.children[0]
        if (backgroundElement && innerElement) {
          const clickedOn = event.target as Node | null
          const wasClickedOutside = (
            backgroundElement.contains(clickedOn)
            && !innerElement.contains(clickedOn)
          )
          if (wasClickedOutside) {
            const closeButton = document.querySelectorAll(CLOSE_BUTTON_ID)?.[0]
            if (closeButton instanceof HTMLElement) {
              closeButton.click()
            }
          }
        }
      }
    }
    window.addEventListener('click', clickOnGreyBackground)
    return () => {
      window.removeEventListener('click', clickOnGreyBackground)
    }
  }, [])

  if (!isLoaded) {
    return null
  }

  return (
    <Script
      strategy="afterInteractive"
      src={`${(typeof window !== 'undefined' && window?.location.protocol) || 'https:'}//tag.wknd.ai/${WUNDERKIND_ID}/i.js`}
    />
  )
}
export default WunderKind
